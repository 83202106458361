import React from "react";
import { FaCcVisa, FaCcMastercard, FaCcAmex } from "react-icons/fa";

const PaymentDetails = props => {
  const { transactions } = props;

  const transaction = (transactions || []).find(({ kind, status }) => kind === 'authorization' && status === 'success');
  const { payment_details, receipt } = transaction || {};
  const { credit_card_company, credit_card_number } = payment_details || {};

  const Icon = {
    'Mastercard': FaCcMastercard,
    'Visa': FaCcVisa,
    'American Express': FaCcAmex,
  }[credit_card_company];

  return (
    <div className="flex text-sm items-center">
      {receipt?.payment_method_details?.type === 'klarna' ? 'Klarna' : (
        <>
          {Icon ? <Icon /> : null}
          <span className="ml-2">{credit_card_number}</span>
        </>
      )}
    </div>
  );
};

export default PaymentDetails;

import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Price from "../../components/Price";
import { withCountries } from "../../queries";

const LineItems = props => {
  const { order, imagesByVariantId, allSanityCountry } = props;
  const {
    line_items,
    current_total_price_set,
    current_subtotal_price_set,
    current_total_tax_set,
    note_attributes,
    refunds,
    total_shipping_price_set,
    total_discounts_set,
  } = order;

  const includeVAT = !note_attributes.find(note => note.name === 'vatNumber');

  const swedishVAT = 0.25;
  const country = useMemo(
    () =>
      allSanityCountry.edges.find(
        edge =>
          edge?.node?.slug?.current ===
          order?.shipping_address?.country_code?.toLowerCase()
      )?.node,
    [allSanityCountry, order]
  );
  const vatRate = country?.vatRate || 0;

  const finalLineItems = useMemo(() => {
    const refundedLineItems = refunds.reduce((acc, refund) => acc + refund.refund_line_items.map(line_item => line_item.line_item_id), []);
    return line_items.filter(line_item => refundedLineItems.indexOf(line_item.id) === -1);
  }, [line_items, refunds])

  const renderLineItem = (line_item, i) => {
    const {
      variant_id,
      title,
      sku,
      variant_title,
      price_set,
      quantity,
      discount_allocations,
    } = line_item;
    const image = imagesByVariantId[variant_id];

    return (
      <div className="flex pb-2" key={i}>
        <div className="w-18 h-24 mr-2 flex-initial">
          <img src={image?.src} className="w-18 h-24 object-cover" />
        </div>

        <div className="flex-1">
          <h3 className="text-sm averta-bold leading-tight">{title}</h3>

          <div className="flex justify-between">
            <div className="">
              <p className="text-sm pb-1 leading-tight">{variant_title}</p>
              <p className="pb-1 text-sm">{sku}</p>

              <div className="pb-2 text-sm">
                {`${quantity} pcs x `}
                <Price
                  amount={price_set.presentment_money.amount}
                  currencyCode={price_set.presentment_money.currency_code}
                  recalculateForVAT={true}
                />
              </div>
            </div>

            <div className="text-right">
              {discount_allocations?.length ? (
                <>
                  <div className="line-through">
                    <Price
                      amount={price_set.presentment_money.amount * quantity}
                      currencyCode={price_set.presentment_money.currency_code}
                      recalculateForVAT={true}
                    />
                  </div>
                  <div className="text-depalmaRed">
                    <Price
                      amount={price_set.presentment_money.amount * quantity - discount_allocations[0].amount_set.presentment_money.amount}
                      currencyCode={price_set.presentment_money.currency_code}
                      recalculateForVAT={true}
                    />
                  </div>
                </>
              ) : (
                <Price
                  amount={price_set.presentment_money.amount * quantity}
                  currencyCode={price_set.presentment_money.currency_code}
                  recalculateForVAT={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="text-sm flex flex-col">
      <div className="overflow-y-scroll flex-1">
        {finalLineItems.map(renderLineItem)}
      </div>

      <div className="border-t border-depalmaDarkGray mt-4 pt-4">
        {total_discounts_set?.presentment_money?.amount && total_discounts_set.presentment_money.amount !== "0.00" ? (
          <div className="flex justify-between mb-4">
            <h4 className="averta-bold">
              <FormattedMessage
                id="shoppingCart.discount"
                defaultMessage="Discount"
              />
            </h4>
            <div className="text-right averta-bold text-depalmaRed">
              <Price
                amount={-total_discounts_set?.presentment_money?.amount}
                currencyCode={current_subtotal_price_set.presentment_money.currency_code}
              />
            </div>
          </div>
        ) : null}

        <div className="flex justify-between mb-4">
          <h4 className="averta-bold">
            <FormattedMessage
              id="shoppingCart.subtotal"
              defaultMessage="Subtotal"
            />
          </h4>
          <div className="text-right averta-bold">
            {current_subtotal_price_set ? (
              <Price
                amount={
                  includeVAT
                    ? current_subtotal_price_set.presentment_money.amount
                    : current_subtotal_price_set.presentment_money.amount / (1 + vatRate)
                }
                currencyCode={
                  current_subtotal_price_set.presentment_money.currency_code
                }
              />
            ) : (
              "-"
            )}
          </div>
        </div>

        <div className="mb-6">
          {/* <PromoCode includeVAT={includeVAT} /> */}
        </div>

        <div className="flex justify-between mb-4">
          <h4 className="averta-bold">
            <FormattedMessage
              id="shoppingCart.estimated-freight"
              defaultMessage="Estimated Freight"
            />
          </h4>
          <div className="text-right averta-bold">
            <Price
              amount={total_shipping_price_set.presentment_money.amount}
              currencyCode={
                total_shipping_price_set.presentment_money.currency_code
              }
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="flex justify-between">
            <h4 className="averta-bold">
              <FormattedMessage
                id="shoppingCart.vat"
                defaultMessage="VAT ({vatRate}%)"
                values={{
                  vatRate: (vatRate || 0) * 100,
                }}
              />
            </h4>
            <div className="text-right averta-bold">
              <Price
                amount={current_total_tax_set.presentment_money.amount}
                currencyCode={
                  current_total_tax_set.presentment_money.currency_code
                }
              />
            </div>
          </div>
          {/* <CartVAT
            cart={cart}
            totalTaxV2={totalTaxV2}
            totalPriceV2={totalPriceV2}
            country={country}
            includeVAT={includeVAT}
          /> */}
        </div>

        <div className="flex justify-between mb-4">
          <h4 className="averta-bold">
            <FormattedMessage id="shoppingCart.total" defaultMessage="Total" />
          </h4>
          <div className="text-right averta-bold">
            {current_total_price_set ? (
              <Price
                amount={current_total_price_set.presentment_money.amount}
                currencyCode={
                  current_total_price_set.presentment_money.currency_code
                }
              />
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCountries(LineItems);

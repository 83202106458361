import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@happy-rabbit/component-library";

import SEO from "../SEO";
import Order from "./Order";

const validOrderId = orderNo => (typeof orderNo === 'string' && orderNo.match(/^[0-9]{13}$/));
const validOrderNo = orderNo => (typeof orderNo === 'string' && orderNo.match(/^[0-9]{4}$/));
const validEmail = email => (typeof email === 'string' && email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/));

const parseParam = param => {
  if (typeof param === 'string' && window && window.atob) {
    try {
      const [orderId, email] = atob(param).split(':');
      if (validOrderId(orderId) && validEmail(email)) {
        return { orderId, email };
      }
    } catch (e) {
      return false
    }
  } else {
    return false
  }
};

const getOrderById = (orderId) => {
  if (window?.location) {
    const url = new URL("/.netlify/functions/shopifyGetOrder", window.location);
    url.searchParams.append('orderId', orderId);
    return fetch(url)
      .then(res => res.json())
      .then(({ order, error }) => {
        if (error) {
          return ['error', null, error];
        } else {
          return ['done', { ...order }];
        }
      })
      .catch(error => {
        console.log(error)
        return ['error', null, error];
      });
  } else {
    return Promise.resolve(['error', null]);
  }
};

const fetchOrder = (orderNo, email) => {
  if (window?.location) {
    const url = new URL("/.netlify/functions/shopifyOrderFetch", window.location);
    url.searchParams.append('orderNo', orderNo);
    url.searchParams.append('email', email);

    return fetch(url)
      .then(res => res.json())
      .then(({ order, transactions, error }) => {
        if (error) {
          return ['error', null, error];
        } else {
          return ['done', { ...order, transactions: transactions }];
        }
      })
      .catch(error => {
        console.log(error)
        return ['error', null, error];
      });
  } else {
    return Promise.resolve(['error', null]);
  }
};

const OrderStatus = props => {
  const { orderParam } = props;

  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [orderNo, setOrderNo] = useState(null);
  const [email, setEmail] = useState(null);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (!orderParam) return;
    const parsed = parseParam(orderParam);
    if (parsed) {
      setStatus('loading');
      getOrderById(parsed.orderId).then(([responseStatus, order, error]) => {
        if (order) {
          window.dataLayer.push({
            event: 'eec.purchase',
            ecommerce: {
              currencyCode: order.currency,
              purchase: {
                actionField: {
                  id: order.order_number,
                  affiliation: 'depalmaworkwear.com',
                  revenue: order.total_price_set.presentment_money.amount,
                  tax: order.total_tax_set.presentment_money.amount,
                  shipping: order.shipping_lines[0].price_set.presentment_money.amount,
                  discount: order.total_discounts_set.presentment_money.amount
                },
                products: order.line_items.map(lineItem => ({
                  id: lineItem.sku,
                  name: lineItem.title,
                  quantity: lineItem.quantity,
                  price: lineItem.price_set.presentment_money.amount,
                  dimension2: lineItem.variant_title.split('/').map(i => i.trim())[0] || '',
                  dimension3: lineItem.variant_title.split('/').map(i => i.trim())[1] || ''
                })),
              },
            }
          });

        }
        setStatus(responseStatus);
        setError(error);
        setOrder(order);
      });

    } else {
      setStatus('invalid');
    }
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (status === 'loading') return;
      if (validEmail(email) && validOrderNo(orderNo)) {
        setStatus('loading');
        fetchOrder(orderNo, email).then(([responseStatus, order, error]) => {
          setStatus(responseStatus);
          setError(error);
          setOrder(order);
        });
      }
    },
    [email, orderNo, status]
  );

  return (
    <>
      <SEO />

      <div className="container mx-auto my-8 px-6 sm:px-0">
        {order ? <Order order={order} /> : (
          <form onSubmit={handleSubmit} className="mx-auto max-w-md">
            <div className="mb-4">
              <label className="text-gray-700 text-sm mb-2 block">
                <span className="averta-bold">
                  <FormattedMessage
                    id="orderStatus.order-number"
                    defaultMessage="Order Number"
                  />
                </span>
                <input
                  className="block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled={status === 'loading'}
                  type="text"
                  name="orderNo"
                  onChange={e => setOrderNo(e.target.value)}
                  required={true}
                  value={orderNo || ''}
                />
              </label>
            </div>

            <div className="mb-4">
              <label className="text-gray-700 text-sm mb-2 block">
                <span className="averta-bold">
                  <FormattedMessage
                    id="orderStatus.email"
                    defaultMessage="Email"
                  />
                </span>
                <input
                  className="block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  disabled={status === 'loading'}
                  type="email"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  required={true}
                  value={email || ''}
                />
              </label>
            </div>

            <Button
              disabled={status === "submitting"}
              theme={"black"}
              type="submit"
            >
              <FormattedMessage
                id="orderStatus.check-status"
                defaultMessage="Check Status"
              />
            </Button>

            {/* {error ? (<p className="text-depalmaRed averta-bold text-sm">{error}</p>) : null} */}
          </form>
        )}
      </div>
    </>
  );
};

export default OrderStatus;

import axios from 'axios'

export function fetchOrder(client, orderId) {
  const gid = btoa(`gid://shopify/Order/${orderId}`)
  const query = `
    fragment MailingAddressFragment on MailingAddress {
      id
      address1
      address2
      city
      company
      country
      firstName
      formatted
      lastName
      latitude
      longitude
      phone
      province
      zip
      name
      countryCode: countryCodeV2
      provinceCode
    }

    fragment VariantFragment on ProductVariant {
      id
      title
      price
      priceV2 {
        amount
        currencyCode
      }
      presentmentPrices(first: 20) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
      weight
      available: availableForSale
      sku
      compareAtPrice
      compareAtPriceV2 {
        amount
        currencyCode
      }
      image {
        id
        src: originalSrc
        altText
      }
      selectedOptions {
        name
        value
      }
      unitPrice {
        amount
        currencyCode
      }
      unitPriceMeasurement {
        measuredType
        quantityUnit
        quantityValue
        referenceUnit
        referenceValue
      }
    }

    fragment VariantWithProductFragment on ProductVariant {
      ...VariantFragment
      product {
        id
        handle
      }
    }

    query ($id: ID!) {
      node(id: $id) {
        ... on Order {
          id
          processedAt
          orderNumber
          subtotalPrice
          subtotalPriceV2 {
            amount
            currencyCode
          }
          totalShippingPrice
          totalShippingPriceV2 {
            amount
            currencyCode
          }
          totalTax
          totalTaxV2 {
            amount
            currencyCode
          }
          totalPrice
          totalPriceV2 {
            amount
            currencyCode
          }
          currencyCode
          totalRefunded
          totalRefundedV2 {
            amount
            currencyCode
          }
          customerUrl
          shippingAddress {
            ...MailingAddressFragment
          }
          lineItems (first: 250) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                title
                variant {
                  ...VariantWithProductFragment
                }
                quantity
                customAttributes {
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
  `

  return axios.post(
    'https://depalma-workwear.myshopify.com/api/2024-04/graphql.json',
    {
      query,
      variables: {
        id:
          'Z2lkOi8vc2hvcGlmeS9PcmRlci8zODc2ODM5MjYwMzU4P2tleT1iZDk0Mzk0NGIzMWU2MjIzMzNjODFhODI1MWQxN2VhYQ==',
      },
    },
    {
      headers: {
        'X-Shopify-Storefront-Access-Token':
          client.config.storefrontAccessToken,
      },
    },
  )
}

export function getVariantImages(client, productIds) {
  let query = `
    query {
      products(first: 250, query: "id:${productIds[0]}") {
        edges {
          node {
            id
            variants (first: 250) {
              edges {
                node {
                  id
                  image {
                    id
                    src: originalSrc
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  query = `
    {
      ${productIds.map(
        (id, i) => `
        productVariant${i}: node(id: "${btoa(
          `gid://shopify/ProductVariant/${id}`,
        )}") {
          ... on ProductVariant {
            id
            image {
              id
              src: originalSrc
              altText
            }
          }
        }      
      `,
      )}
    }
  `

  return axios
    .post(
      'https://depalma-workwear.myshopify.com/api/2024-04/graphql.json',
      {
        query,
        variables: { id: productIds },
      },
      {
        headers: {
          'X-Shopify-Storefront-Access-Token':
            client.config.storefrontAccessToken,
        },
      },
    )
    .then(({ data: { data } }) =>
      Object.values(data).map((productVariant) => ({
        ...productVariant,
        variant_id: parseInt(
          atob(productVariant.id).replace('gid://shopify/ProductVariant/', ''),
        ),
      })),
    )
    .catch((err) => console.error(err))
}

import React from "react";
import { FormattedMessage } from "react-intl";
import Address from "./Address";
import PaymentDetails from "./PaymentDetails";

const CustomerInformation = props => {
  const { order } = props;
  const {
    billing_address,
    customer,
    shipping_address,
    shipping_lines,
    transactions,
  } = order;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 text-sm">
      <div className="flex-1">
        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.contact-information"
            defaultMessage="Contact information"
          />
        </h4>
        <p>{order.customer.email}</p>

        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.shipping-address"
            defaultMessage="Shipping address"
          />
        </h4>
        <Address address={shipping_address} />

        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.shipping-method"
            defaultMessage="Shipping method"
          />
        </h4>
        <p>{shipping_lines?.length ? shipping_lines[0].code : null}</p>
      </div>
      <div className="flex-1">
        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.payment-method"
            defaultMessage="Payment method"
          />
        </h4>
        <PaymentDetails transactions={transactions} />

        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.billing-address"
            defaultMessage="Billing address"
          />
        </h4>
        <Address address={billing_address} />
      </div>
    </div>
  );
};

export default CustomerInformation;

import React from "react";

const Address = props => {
  const { address } = props;

  return (
    <>
      <p>{[address.first_name, address.last_name].filter(Boolean).join(' ')}</p>
      <p>{address.address1}</p>
      <p>{address.address2}</p>
      <p>{[address.zip, address.city].filter(Boolean).join(' ')}</p>
      <p>{address.country}</p>
      <p>{address.phone}</p>
    </>
  );
};

export default Address;

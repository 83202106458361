import React from "react";
import { FormattedMessage } from "react-intl";

const TrackingDetails = props => {
  const { order } = props;
  const { fulfillments } = order;

  const renderFulfillment = (fulfillment, i) => {
    const { tracking_company, tracking_number, tracking_url } = fulfillment;

    return (
      <div key={i} className="mb-2">
        <h4>{`${tracking_company} tracking numer`}</h4>
        <p><a href={tracking_url} target="_blank" rel="noreferrer" className="text-blue-700">{tracking_number}</a></p>
      </div>
    );
  };

  return (
    <div className="">
      <h4 className="mb-2">
        <FormattedMessage
          id="orderStatus.your-order-is-confirmed"
          defaultMessage="Your order is confirmed"
        />
      </h4>
      
      {fulfillments?.length ? fulfillments.map(renderFulfillment) : (
        <p>
          <FormattedMessage
            id="orderStatus.you-will-receive-an-email"
            defaultMessage="You’ll receive an email when your order is ready."
          />
        </p>
      )}
    </div>
  );
};

export default TrackingDetails;
